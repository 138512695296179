import {Injectable} from '@angular/core';
import {Location} from '@angular/common';
import {IField, IList} from '../interfaces/field.interface';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(public location: Location) {
  }

  public locationBack(): void {
    this.location.back();
  }

  public findObjectByName(obj: any, search: string): any {
    let found = obj.find(n => n.name === search);
    return found || null;
  }

  public getObjectByName(entryObject: IField[], name: string): IField {
    if (!entryObject) {
      return {} as IField;
    }
    const outObject = entryObject.find(element => element.name === name)
    if (outObject) {
      return outObject
    } else {
      return {} as IField;
    }
  }

  public parseDateToSlash(date: string) {
    if (date.includes('.')) {
      let tempArray = [];
      tempArray = date.split(".");
      return tempArray[2] + '-' + tempArray[1] + '-' + tempArray[0]
    } else {
      return date
    }
  }


  public checkMultipleRequiredGroups(list: IList): number {
    let tempArray: string[] = [];
    if (list) {
      list?.active.forEach(item => {
        if (item && item.mandatoryUniqueKey) {
          tempArray.push(item.mandatoryUniqueKey);
        }
      });
      list?.inactive.forEach(item => {
        if (item && item.mandatoryUniqueKey) {
          tempArray.push(item.mandatoryUniqueKey);
        }
      });
    }
    let uniqueArray = [...new Set(tempArray)];
    return uniqueArray.length;
  }


  public parseDateToPoint(date: string): string {
    if (!date) return '';

    if (date.includes('-')) {
      const tempArray = date.split("-");
      // Ensure valid date parts
      const year = tempArray[0]?.trim();
      const month = tempArray[1]?.padStart(2, '0');
      const day = tempArray[2]?.padStart(2, '0');

      return `${day}.${month}.${year}`;
    }
    return date;
  }}
