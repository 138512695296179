import {APP_INITIALIZER, enableProdMode, importProvidersFrom, LOCALE_ID} from '@angular/core';
import {environment} from './environments/environment';
import {CookieService} from 'ngx-cookie-service';
import {HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {HttpInterceptor} from './app/auth/http.interceptor';
import {EnvironmentLoaderService} from './app/services/environment-loader.service';
import {CommonModule} from '@angular/common';
import {bootstrapApplication, BrowserModule} from '@angular/platform-browser';
import {provideAnimations} from '@angular/platform-browser/animations';
import {ToastrModule} from 'ngx-toastr';
import {FormsModule} from '@angular/forms';
import {AppComponent} from './app/app.component';
import {provideRouter} from "@angular/router";
import {routes} from "./routes";
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      CommonModule,
      BrowserModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        }
      }),
      ToastrModule.forRoot({
        timeOut: 5000,
        positionClass: 'toast-bottom-right',
        newestOnTop: false,
        progressBar: true,
        progressAnimation: 'increasing',
        tapToDismiss: true
      }),
      FormsModule
    ),
    CookieService,
    {provide: LOCALE_ID, useValue: 'de'},
    {provide: HTTP_INTERCEPTORS, useClass: HttpInterceptor, multi: true},
    HttpClient,
    EnvironmentLoaderService,
    {
      provide: APP_INITIALIZER,
      useFactory: (envLoader: EnvironmentLoaderService) => () => envLoader.loadEnvConfig('assets/config/app-config.json'),
      deps: [EnvironmentLoaderService],
      multi: true
    },
    provideAnimations(),
    provideRouter(routes),
    provideHttpClient(withInterceptorsFromDi())
  ]
}).catch(err => console.error(err));

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

