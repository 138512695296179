import {inject} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from './auth.service';
import {catchError, map, tap} from 'rxjs/operators';
import {of} from 'rxjs';

export const authGuard = () => {
  return () => {
    const authService = inject(AuthService);
    const router = inject(Router);

    return authService.isAuthenticated().pipe(
      catchError(() => of(false)),
      tap(isAuthenticated => {
        if (!isAuthenticated && !router.url.includes('login')) {
          void router.navigate(['/login']);
        }
      }),
      map(isAuthenticated => !!isAuthenticated)
    );
  };
};
