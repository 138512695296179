import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable, of, ReplaySubject, throwError} from 'rxjs';
import {environment} from '../../environments/environment';
import {CookieService} from 'ngx-cookie-service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {HelperService} from "../services/helper.service";
import {catchError, map, tap} from 'rxjs/operators';
import { Buffer } from 'buffer';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public outline$: ReplaySubject<any> = new ReplaySubject<any>(null);
  public userData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public username$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);

  constructor(
    private translate: TranslateService,
    private cookieService: CookieService,
    private helperService: HelperService,
    private http: HttpClient,
    private router: Router,
    private toastr: ToastrService
  ) {
    window.onstorage = (a: any) => {
      {
        if(localStorage.getItem('signOut'))
        {
          this.router.navigate(['/login']);
        }
      };
    }
  }

  public isAuthenticated(): Observable<boolean> {
    return this.getLogin().pipe(
      tap(data => {
        this.userData$.next(data);
      }),
      map(() => {
        return true;
      }),
      catchError(error => {
        this.userData$.next(null);

        if (error instanceof HttpErrorResponse && error.status === 401) {
          return of(false);
        }
        return throwError(error);
      })
    );
  }

  public getOutline(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/user/${environment.realmConfig}/outline`);
  }


  public getLogin(headers?: HttpHeaders): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/token/login`, {headers: headers});
  }


  public login(username: string, password: string, realmName: string): Promise<boolean> {

    let headers: HttpHeaders
    headers = new HttpHeaders({Authorization: 'Basic ' + Buffer.from(username + ':' + password, ).toString('base64'), Realm: realmName});

    return new Promise((resolve) => {
      this.getLogin(headers).subscribe(
        (response: any) => {

          this.userData$.next(response);
          localStorage.removeItem('signOut');
          this.router.navigate(['/']);
          if(response.realm === "master") {
            environment.realmConfig = environment.realmNames[0];
          } else {
            environment.realmConfig = response.realm;
          }
          resolve(true);
        },
        () => {
          this.toastr.error(this.translate.instant('MESSAGES.ERROR.AUTH'), this.translate.instant('MESSAGES.ERROR.AUTH_TITLE'));
          this.router.navigate(['/login']);
          resolve(false);
        });
    });
  }

  public logout() {
    this.deleteCookies();
    localStorage.clear();
    this.outline$.next(undefined);
    localStorage.setItem('signOut', 'true');
    this.http.post<any>(`${environment.apiUrl}/logout`, {}).subscribe(
      (response: any) => {
        this.router.navigate(['/login']);
      }
    )
  }


  public deleteCookies() {
    // es wird auf die outline subscribed, um alle cookies in den Unterpfaden zu löschen.
    // die Standardfunktion des Services deleteAll() löscht oftmals die Unterpfade nicht
    this.outline$.subscribe(outline => {
      if (outline) {
        outline.forEach(element => {
          this.cookieService.deleteAll(`/${element.name.toLowerCase()}`);
        })
      }
    })
    this.cookieService.deleteAll();
  }
}
